import type { TWebsiteConfig } from '~/typings/types/website-config.types';
import useDisableScrollbar from '~/composables/dom/useDisableScrollbar';

export interface IUseDialogs {
  /**
   * @description Open form dialog
   */
  openFormDialog: () => void;

  /**
   * @description Close form dialog
   */
  closeFormDialog: () => void;
}

/**
 * @description Composable функция для изменения состояний диалоговых окон на сайте.
 * @returns {IUseDialogs}
 */
export default function useDialogs(): IUseDialogs {
  const websiteConfig = useState<TWebsiteConfig>('config');
  const { compensateScrollbar, deCompensateScrollbar } = useDisableScrollbar();

  function openFormDialog() {
    websiteConfig.value.dialogs.forms.state = true;
  }

  function closeFormDialog() {
    websiteConfig.value.dialogs.forms.state = false;
  }

  watch(
    () => websiteConfig.value.dialogs.forms.state,
    () => {
      websiteConfig.value.menusState.desktop || websiteConfig.value.dialogs.forms.state
        ? compensateScrollbar()
        : deCompensateScrollbar();
    },
  );

  return {
    openFormDialog,
    closeFormDialog,
  };
}
